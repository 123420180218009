export default {
  proxy_path: "https://g4jn9ruiai.execute-api.ap-southeast-2.amazonaws.com/api",
  proxy_path_geojson_api: "https://3o68qug7v7.execute-api.ap-southeast-2.amazonaws.com/api",
  google_api_key: "AIzaSyBthLqIniCp2bAvxhf4mvKwBT0I6ENtM7M",
  main_company_term_id: "6",
  crm_base_url: "https://crm.ozminerals.forwoodsafety.com",
  forwood_id_url: "https://id.ozminerals.forwoodsafety.com?redirect_uri=https://mapreport.ozminerals.forwoodsafety.com",
  custom_tile_url: "https://mapoverlays.geoeditor.ozminerals.forwoodsafety.com",
  Auth: {
    userPoolId: "ap-southeast-2_8ygXVgFwA",
    userPoolWebClientId: "6kg1lq2c6c8g9ecn3vfan6op8t",
    cookieStorage: {
      domain: ".ozminerals.forwoodsafety.com",
      secure: true
    }
  }
};
